<template>
  <b-card>
    <form @submit.prevent="updatePassword">
      <div class="form-group">
        <label for="currentPassword">Current Password</label>
        <div class="input-wrapper">
          <input
            id="currentPassword"
            v-model="currentPassword"
            :type="currentPasswordType"
            class="form-control"
            placeholder="Input Current Password"
            required
            autocomplete="off"
            :disabled="$store.state.user.loadingSubmit"
          />
          <feather-icon
            class="toggle-icon cursor-pointer"
            :icon="currentPasswordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
            @click="togglePasswordVisibility('current')"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="newPassword">New Password</label>
        <div class="input-wrapper">
          <input
            id="newPassword"
            v-model="newPassword"
            :type="newPasswordType"
            class="form-control"
            placeholder="Input New Password"
            required
            autocomplete="off"
            :disabled="$store.state.user.loadingSubmit"
          />
          <feather-icon
            class="toggle-icon cursor-pointer"
            :icon="newPasswordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
            @click="togglePasswordVisibility('newPassword')"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="confirmPassword">Confirm New Password</label>
        <div class="input-wrapper">
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            :type="confirmPasswordType"
            class="form-control"
            placeholder="Confirm New Password"
            required
            autocomplete="off"
            :disabled="$store.state.user.loadingSubmit"
          />
          <feather-icon
            class="toggle-icon cursor-pointer"
            :icon="confirmPasswordType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
            @click="togglePasswordVisibility('confirmPassword')"
          />
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        :disabled="$store.state.user.loadingSubmit"
      >
        <b-spinner
          v-if="$store.state.user.loadingSubmit"
          class="mr-50"
          small
        />
        Update Password
      </button>
    </form>
  </b-card>
</template>

<script>
import { BCard, BSpinner } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BSpinner,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordType: 'password',
      newPasswordType: 'password',
      confirmPasswordType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility(field) {
      if (field === 'current') {
        this.currentPasswordType = this.currentPasswordType === 'password' ? 'text' : 'password'
      } else if (field === 'newPassword') {
        this.newPasswordType = this.newPasswordType === 'password' ? 'text' : 'password'
      } else if (field === 'confirmPassword') {
        this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password'
      }
    },
    async updatePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'AlertTriangleIcon',
            text: 'New Password & Password Confirmation didnt match',
            variant: 'warning',
          },
        })
        return
      }

      try {
        await this.$store.dispatch('user/updatePassword', {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.confirmPassword,
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Success Change Password, please login with new password',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            text: 'Failed to update password',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.input-wrapper {
  position: relative;
}
.input-wrapper .toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
